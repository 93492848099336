import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
export const defaultDarkMode = false;

const familyFontiCash = 'Inter, sans-serif';

//Colors
const blue = '#2799f0';
const blue200 = '#cbe1f1';
const blue400 = '#1263a2';
const yellow = '#ffc95c';
const black = '#0d0d0d';
const blackText = '#212121';
const gray = '#4a4a48';
const green = '#3BBBB3';
const purple = '#705CF2';
const bgDefault = '#3a4651';
const white = '#ffffff';

//Purples
const purple200 = '#F1EFFE';
const purple600 = '#6553DA';

//Grays
const gray100 = '#f7f7f7';
const gray200 = '#f3f3f3';
const gray300 = '#e5e7eb';
const gray350 = '#d1d1d1';
const gray400 = '#6B7180';
const gray500 = '#a4a4a3';
const gray600 = '#777776';
const gray700 = '#FFFDFD';
const gray800 = '#323231';

//Red
const redb600 = '#660000';
const redb400 = '#990000';
const redb200 = '#cc0000';
const red = '#D90416';
const redw400 = '#ff4b54';
const redw600 = '#ff9999';
const redw700 = '#ffb8b8';
const redw850 = '#ffdbdb';
const redw900 = '#ffe6e6';
const redw950 = '#FFF7F8A6';

//Green
const greenb840 = '#237f65';
const greenb820 = '#2faa87';
const greenw700 = '#c4f2e5';
const greenw950 = '#f5fcfa';

const customTheme = {
  darkMode: defaultDarkMode,
  customColors: {
    blue,
    yellow,
    black,
    blackText,
    gray,
    green,
    purple,
    purples: {
      purple200,
      purple600,
    },
    red,
    reds: {
      redb600,
      redb400,
      redb200,
      redw400,
      redw600,
      redw700,
      redw850,
      redw900,
      redw950,
    },
    greens: {
      greenb840,
      greenb820,
      greenw700,
      greenw950,
    },
    grays: {
      gray100,
      gray200,
      gray300,
      gray350,
      gray400,
      gray500,
      gray600,
      gray700,
      gray800,
    },
    blue200,
    blue400,
  },
};

declare module '@mui/material/styles' {
  interface Theme {
    darkMode: boolean;
    familyFontiCash: string;
    customColors: {
      blue: string;
      yellow: string;
      black: string;
      blackText: string;
      gray: string;
      green: string;
      purple: string;
      purples: {
        purple200: string;
        purple600: string;
      };
      red: string;
      reds: {
        redb600: string;
        redb400: string;
        redb200: string;
        redw400: string;
        redw600: string;
        redw700: string;
        redw850: string;
        redw900: string;
        redw950: string;
      };
      greens: {
        greenb840: string;
        greenb820: string;
        greenw700: string;
        greenw950: string;
      };
      grays: {
        gray100: string;
        gray200: string;
        gray300: string;
        gray350: string;
        gray400: string;
        gray500: string;
        gray600: string;
        gray700: string;
        gray800: string;
      };
      blue200: string;
      blue400: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    darkMode: boolean;
    familyFontiCash: string;
    customColors: {
      blue: string;
      yellow: string;
      black: string;
      blackText: string;
      gray: string;
      green: string;

      purple: string;
      purples: {
        purple200: string;
        purple600: string;
      };

      red: string;
      reds: {
        redb600: string;
        redb400: string;
        redb200: string;
        redw400: string;
        redw600: string;
        redw700: string;
        redw850: string;
        redw900: string;
        redw950: string;
      };
      greens: {
        greenb840: string;
        greenb820: string;
        greenw700: string;
        greenw950: string;
      };
      grays: {
        gray100: string;
        gray200: string;
        gray300: string;
        gray350: string;
        gray400: string;
        gray500: string;
        gray600: string;
        gray700: string;
        gray800: string;
      };
      blue200: string;
      blue400: string;
    };
  }
}
const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: { backgroundColor: '#FFF', color: '#2D2D2D' },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'black',
          textDecorationColor: 'black',
        },
      },
    },
  },
  familyFontiCash: familyFontiCash,
  palette: {
    primary: {
      main: purple,
    },
    secondary: {
      main: red,
    },
    warning: {
      main: yellow,
    },
    background: {
      default: bgDefault,
    },
    common: {
      black: black,
      white: white,
    },
    divider: gray300,
    grey: {
      '100': gray100,
      '200': gray200,
      '300': gray300,
      '400': gray400,
      '500': gray500,
      '600': gray600,
      '700': gray700,
      '800': gray800,
    },
    error: {
      main: redw400,
    },
  },
  ...customTheme,
});

const useStyles = makeStyles(() => ({
  primary: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.customColors.purple} !important`,
  },
  success: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.customColors.green} !important`,
  },
  danger: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.error.main} !important`,
  },
  status600: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.grey[600]} !important`,
  },
  default: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.grey[500]} !important`,
  },
}));
/**
 * Returns the classes of `status`
 *
 *
 * @param {string} originalStatus Status string
 * @returns {string} Returns the classes of the status
 */
export const getStatusColor = (status: string) => {
  const classes = useStyles();
  switch (status) {
    case 'PENDING':
      return classes.primary;
    case 'CREATED':
    case 'COMPLETED':
    case 'CONFIRMED':
    case 'APPROVED':
    case 'NEW':
    case 'ASSIGNED':
      return classes.success;
    case 'ACTIVE':
      return classes.success;
    case 'CANCELED':
    case 'CANCELLED':
    case 'FAILED':
    case 'DENIED':
      return classes.danger;
    case 'INACTIVE':
      return classes.danger;
    case 'APPROVED_BY_REQUEST':
      return classes.success;
    case 'REJECTED':
    case 'FAIL':
      return classes.danger;
    case 'FOR_REVIEW':
      return classes.primary;
    case 'FOR_REVIEW_FINANCE':
      return classes.primary;
    default:
      return classes.default;
  }
};

export default theme;
