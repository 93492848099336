import { createSelector } from 'reselect';

import { SessionState, User } from './types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AuthService } from '../../services';
import { LoginRequest } from '../../services/auth-service';

const initialState: SessionState = { user: null, authenticated: false };

const loginAsync = createAsyncThunk<User | null, LoginRequest>(
  'session/login',
  async (request, { rejectWithValue }) => {
    try {
      return await AuthService.login(request);
    } catch {
      return rejectWithValue('NOK');
    }
  },
);

const logout = createAsyncThunk('session/logout', async () => {
  const response = await AuthService.logout();
  return response === 'OK';
});

const loginReducer = (state: SessionState, action: PayloadAction<User>) => {
  state.user = action.payload;
  state.authenticated = true;
};

const logoutReducer = (state: SessionState) => {
  state.user = null;
  state.authenticated = false;
};

const updateUserReducer = (state: SessionState, action: PayloadAction<Partial<User>>) => {
  if (!!state.user) {
    state.user = { ...state.user, ...action.payload };
  } else {
    state.user = action.payload as User;
  }
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    login: loginReducer,
    updateUser: updateUserReducer,
    logout: logoutReducer,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.fulfilled, (state, action: PayloadAction<User | null>) => {
        if (action.payload !== null) {
          state.user = action.payload;
          state.authenticated = true;
        }
      })
      .addCase(logout.fulfilled, logoutReducer)
      .addCase(logout.rejected, logoutReducer);
  },
});

const { reducer, actions } = sessionSlice;
const { login, updateUser } = actions;

// Selectors
const getSessionState = (state: RootState): SessionState => state.session;
export const getUser = createSelector(getSessionState, (state) => state.user);
export const getIsAuthenticated = createSelector(getSessionState, (state) => state.authenticated);

export { login, logout, updateUser };

export default reducer;
