/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n, { TOptions as i18nTOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import ChainedBackend from 'i18next-chained-backend';
import IntlPolyfill from 'intl';
import moment from 'moment';

import 'intl/locale-data/jsonp/en';
// import 'moment/min/locales';
import 'moment/locale/es';
import 'moment/locale/id';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pt';
import 'moment/locale/th';
import 'moment/locale/tr';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import 'moment/locale/ru';
import { pascalCase, removePrefix } from '../helpers/string';

const availableLanguages = ['en', 'es', 'pt', 'zh', 'zh-TW'];
const detection = {
  lookupLocalStorage: 'language',
};

// get The current language of i18n
export const getLanguage = () => {
  return localStorage.getItem('language') ?? 'en';
};

i18n
  .use(ChainedBackend)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: availableLanguages.includes(getLanguage()) ? 'all' : 'languageOnly',
    fallbackLng: 'en',
    detection,
    debug: false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

const intlInstanceNumberFormat = (
  lng: string,
  number: number,
  options: IntlPolyfill.NumberFormatOptions,
) =>
  new IntlPolyfill.NumberFormat(lng, options)
    .formatToParts(number)
    .map(({ type, value }) => {
      if (type === 'currency') {
        return `<span className="currency">${value}</span>`;
      } else {
        return value;
      }
    })
    .reduce((string, part) => string + part, '');

export const formatterNumber = (number: number, options: IntlPolyfill.NumberFormatOptions) =>
  intlInstanceNumberFormat(getLanguage() ?? 'en', number, options);
moment.locale(getLanguage());

export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang);
};

export const translateWithPrefix = (
  term: string,
  prefix = 'label',
  params?: i18nTOptions | string,
) => {
  const removePossiblyRedundantPrefix = removePrefix(term, prefix);
  const key = `${prefix}${pascalCase(removePossiblyRedundantPrefix)}`;
  const translatedKey = translate(key, typeof params === 'object' ? params : undefined);
  return translatedKey === key ? term : translatedKey;
};

export const translate = (key: string, params?: i18nTOptions) =>
  ourEscapeMethod(i18n.t(key, params));

const ourEscapeMethod = (s: string) => {
  if (!s) {
    return '';
  }
  return s.replace('\\', '');
};

export default i18n;
