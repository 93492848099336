import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import { crashReport } from './middlewares';
import globalReducer from './global';
import sessionReducer from './session';
import filtersReducer from './filters';

// const composeEnhancers =
//   ((process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'test') &&
//     window &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
//   compose;

const filtersPersistConfig = {
  key: '__RESELLER_PANEL__FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const globalPersistConfig = {
  key: '__RESELLER_PANEL__GLOBAL__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const sessionPersistConfig = {
  key: '__RESELLER_PANEL__SESSION__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const store = configureStore({
  reducer: {
    filters: persistReducer(filtersPersistConfig, filtersReducer),
    global: persistReducer(globalPersistConfig, globalReducer),
    session: persistReducer(sessionPersistConfig, sessionReducer),
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).prepend(crashReport),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

// export store singleton instance
export default store;
