/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../config/constants';

import i18n from '../config/i18n';
import { useAppDispatch } from '../redux/hooks';
import { logout } from '../redux/session';

const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any)['opera'];
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return 'iOS';
  } else if (userAgent.match(/Android/i)) {
    return 'Android';
  } else {
    return 'unknown';
  }
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

export interface ApiResponse<T = any> {
  data: T;
  hasError?: boolean;
}

export class HttpService {
  instance: AxiosInstance | any = null;

  constructor(gateway = false) {
    if (this.instance === null) {
      this.instance = axios.create({
        baseURL: baseURL,
        withCredentials: true,
      });
    }
    (this.instance as AxiosInstance).interceptors.request.use((request) => {
      const lang = i18n.language.substring(0, 2);
      const os = getMobileOperatingSystem();
      request.headers.common['Accept-Language'] = lang || 'es';
      if (!gateway) {
        request.headers.common['platform'] = `WEB${
          os !== 'unknown' ? '_' + os.toLocaleUpperCase() : ''
        }`;
      }
      return request;
    });
    this.instance.interceptors.response.use(
      (response: any) => {
        return { data: response.data, hasError: false } as ApiResponse<any>;
      },
      (error: AxiosError): ApiResponse<Error> => {
        const {
          response = { status: 500, data: { error: '', description: '', status: 500 } },
        } = error;
        const { status, data } = response;
        if (HttpService.logoutStatus.includes(status)) {
          window.location.href = RoutePaths.login;
        }
        return { data: { ...data, status, hasError: true }, hasError: true };
      },
    );
  }

  static get logoutStatus() {
    return [
      401, // Unauthorized
      403, // Access denied
    ];
  }

  static get warningStatus() {
    return [412];
  }

  async handleLogout() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    try {
      const response = await dispatch(logout());
      if (response) {
        history.push(RoutePaths.login);
      }
    } catch {}
  }

  async resolveResponse<T = any>(promise: ApiResponse<T | Error>) {
    const response = await promise;
    return new Promise<T>((resolve, reject) => {
      const { data } = response as any;
      if (response.hasError) {
        reject(data);
      }
      resolve(data as T);
    });
  }

  request<T = any>(options: AxiosRequestConfig): Promise<T> {
    return this.resolveResponse<T>(this.instance.request(options));
  }

  get<T = any>(url: string, params: any = {}): Promise<T> {
    let join = '';
    let qs = '';
    if (Object.keys(params).length) {
      qs = this.queryString(params);
      join = url.includes('?') ? '&' : '?';
    }

    return this.resolveResponse<T>(this.instance.get(url + join + qs));
  }

  post<T = any>(url: string, params: any = {}, config: AxiosRequestConfig = {}): Promise<T> {
    return this.resolveResponse<T>(this.instance.post(url, params, config));
  }

  postFile<T = any>(url: string, file: Blob): Promise<T> {
    const formData = new FormData();
    formData.append('file', file);
    const headers = {
      'content-type': 'multipart/form-data',
    };

    return this.resolveResponse<T>(this.instance.post(url, formData, headers));
  }

  put<T = any>(url: string, params: any = {}): Promise<T> {
    return this.resolveResponse<T>(this.instance.put(url, params));
  }

  patch<T = any>(url: string, params: any = {}): Promise<T> {
    return this.resolveResponse<T>(this.instance.patch(url, params));
  }

  delete(url: string, params: any = {}): AxiosPromise {
    return this.instance.delete(url, params);
  }

  queryString = (params: any): string =>
    Object.keys(params)
      .map((key) => {
        if (params[key] instanceof Array) {
          return params[key]
            .map((value: string) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join('&');
}

const HttpServiceInstance = new HttpService();

export default HttpServiceInstance;
