/* eslint-disable @typescript-eslint/no-explicit-any */

export const getTag = (value: unknown): string => {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]';
  }
  return toString(value);
};

const isSymbol = (value: any): boolean => {
  const type = typeof value;
  return (
    type === 'symbol' || (type === 'object' && value != null && getTag(value) === '[object Symbol]')
  );
};

/** Used to compose unicode character classes. */
const rsAstralRange = '\\ud800-\\udfff';
const rsComboMarksRange = '\\u0300-\\u036f';
const reComboHalfMarksRange = '\\ufe20-\\ufe2f';
const rsComboSymbolsRange = '\\u20d0-\\u20ff';
const rsComboMarksExtendedRange = '\\u1ab0-\\u1aff';
const rsComboMarksSupplementRange = '\\u1dc0-\\u1dff';
const rsComboRange =
  rsComboMarksRange +
  reComboHalfMarksRange +
  rsComboSymbolsRange +
  rsComboMarksExtendedRange +
  rsComboMarksSupplementRange;
const rsDingbatRange = '\\u2700-\\u27bf';
const rsLowerRange = 'a-z\\xdf-\\xf6\\xf8-\\xff';
const rsMathOpRange = '\\xac\\xb1\\xd7\\xf7';
const rsNonCharRange = '\\x00-\\x2f\\x3a-\\x40\\x5b-\\x60\\x7b-\\xbf';
const rsPunctuationRange = '\\u2000-\\u206f';
const rsSpaceRange =
  ' \\t\\x0b\\f\\xa0\\ufeff\\n\\r\\u2028\\u2029\\u1680\\u180e\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200a\\u202f\\u205f\\u3000';
const rsUpperRange = 'A-Z\\xc0-\\xd6\\xd8-\\xde';
const rsVarRange = '\\ufe0e\\ufe0f';
const rsBreakRange = rsMathOpRange + rsNonCharRange + rsPunctuationRange + rsSpaceRange;

/** Used to compose unicode capture groups. */
const rsApos = "['\u2019]";
const rsBreak = `[${rsBreakRange}]`;
const rsCombo = `[${rsComboRange}]`;
const rsDigit = '\\d';
const rsDingbat = `[${rsDingbatRange}]`;
const rsLower = `[${rsLowerRange}]`;
const rsMisc = `[^${rsAstralRange}${
  rsBreakRange + rsDigit + rsDingbatRange + rsLowerRange + rsUpperRange
}]`;
const rsFitz = '\\ud83c[\\udffb-\\udfff]';
const rsModifier = `(?:${rsCombo}|${rsFitz})`;
const rsNonAstral = `[^${rsAstralRange}]`;
const rsRegional = '(?:\\ud83c[\\udde6-\\uddff]){2}';
const rsSurrPair = '[\\ud800-\\udbff][\\udc00-\\udfff]';
const rsUpper = `[${rsUpperRange}]`;
const rsZWJ = '\\u200d';

/** Used to compose unicode regexes. */
const rsMiscLower = `(?:${rsLower}|${rsMisc})`;
const rsMiscUpper = `(?:${rsUpper}|${rsMisc})`;
const rsOptContrLower = `(?:${rsApos}(?:d|ll|m|re|s|t|ve))?`;
const rsOptContrUpper = `(?:${rsApos}(?:D|LL|M|RE|S|T|VE))?`;
const reOptMod = `${rsModifier}?`;
const rsOptVar = `[${rsVarRange}]?`;
const rsOptJoin = `(?:${rsZWJ}(?:${[rsNonAstral, rsRegional, rsSurrPair].join('|')})${
  rsOptVar + reOptMod
})*`;
const rsOrdLower = '\\d*(?:1st|2nd|3rd|(?![123])\\dth)(?=\\b|[A-Z_])';
const rsOrdUpper = '\\d*(?:1ST|2ND|3RD|(?![123])\\dTH)(?=\\b|[a-z_])';
const rsSeq = rsOptVar + reOptMod + rsOptJoin;
const rsEmoji = `(?:${[rsDingbat, rsRegional, rsSurrPair].join('|')})${rsSeq}`;

const reUnicodeWords = RegExp(
  [
    `${rsUpper}?${rsLower}+${rsOptContrLower}(?=${[rsBreak, rsUpper, '$'].join('|')})`,
    `${rsMiscUpper}+${rsOptContrUpper}(?=${[rsBreak, rsUpper + rsMiscLower, '$'].join('|')})`,
    `${rsUpper}?${rsMiscLower}+${rsOptContrLower}`,
    `${rsUpper}+${rsOptContrUpper}`,
    rsOrdUpper,
    rsOrdLower,
    `${rsDigit}+`,
    rsEmoji,
  ].join('|'),
  'g',
);

/**
 * Splits a Unicode `string` into an array of its words.
 *
 * @private
 * @param {string} The string to inspect.
 * @returns {RegExpMatchArray | null} Returns the words of `string`.
 */
export const unicodeWords = (string: string): RegExpMatchArray | null => {
  return string.match(reUnicodeWords);
};

/// ^   = beginning of string
/// $   = ending of string
/// 'i' = case insenstivie
/// :-)

/**
 * Remove the prefix of `string`
 *
 * @param {string} str String to remove prefix.
 * @param {string} prefix Prefix to remove.
 * @returns Returns the string without the prefix.
 */
export const removePrefix = (str: string, prefix: string) =>
  str.replace(new RegExp(`^\\${prefix}`, 'i'), '');
/**
 * Converts `string` to [camel case](https://en.wikipedia.org/wiki/CamelCase).
 *
 * @param {string} str The string to convert.
 * @returns {string} Returns the camel cased string.
 *
 */
export const camelCase = (str: string): string =>
  words(toString(str).replace(/['\u2019]/g, '')).reduce((result, word, index) => {
    word = word.toLowerCase();
    return result + (index ? upperFirst(word) : word);
  }, '');
/**
 * Converts the first character of `string` to upper case and the remaining
 * to lower case.
 *
 * @param {string} str The string to capitalize.
 * @returns {string} Returns the capitalized string.
 */
export const capitalize = (str: string): string => upperFirst(toString(str).toLowerCase());
/**
 * Converts `string` to
 * [start case](https://en.wikipedia.org/wiki/Letter_case#Stylistic_or_specialised_usage).
 *
 * @param {string} [str=''] The string to convert.
 * @returns {string} Returns the start cased string.
 */
export const startCase = (str: string): string =>
  words(toString(str).replace(/['\u2019]/g, '')).reduce(
    (result, word, index) => result + (index ? ' ' : '') + upperFirst(word),
    '',
  );
/**
 * Converts `string` to pascal case.
 *
 * @param {string} str String to convert
 * @returns {string} Returns the pascal cased string.
 *
 */
export const pascalCase = (str: string): string =>
  str.substr(0, 1).toUpperCase() + camelCase(str.substr(1));
/**
 * Converts `string` to
 * [snake case](https://en.wikipedia.org/wiki/Snake_case).
 *
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the snake cased string.
 */
export const snakeCase = (string: string): string =>
  words(toString(string).replace(/['\u2019]/g, '')).reduce(
    (result, word, index) => result + (index ? '_' : '') + word.toLowerCase(),
    '',
  );
/**
 * Creates a function like `lowerFirst` or `upperFirst`.
 *
 * @private
 * @param {string} methodName The name of the `String` case method to use.
 * @returns {Function} Returns the new case function.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const createCaseFirst = (methodName: string): Function => (string: string) => {
  if (!string) return '';
  const character = string.substr(0, 1) as any;
  const trailing = string.slice(1);
  return character[methodName]() + trailing;
};
/**
 * Converts the first character of `string` to upper case.
 *
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the converted string.
 */
export const upperFirst = createCaseFirst('toUpperCase');
/**
 * Converts the first character of `string` to lower case.
 *
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the converted string.
 */
export const lowerFirst = createCaseFirst('toLowerCase');
/**
 * Converts `value` to a string. An empty string is returned for `null`
 * and `undefined` values. The sign of `-0` is preserved.
 *
 * @param {*} value The value to convert.
 * @returns {string} Returns the converted string.
 *
 */
export const toString = (value: any): string => {
  if (value == null) {
    return '';
  }
  // Exit early for strings to avoid a performance hit in some environments.
  if (typeof value === 'string') {
    return value;
  }
  if (Array.isArray(value)) {
    // Recursively convert values (susceptible to call stack limits).
    return `${value.map((other) => (other == null ? other : toString(other)))}`;
  }
  if (isSymbol(value)) {
    return value.toString();
  }
  /** Used as references for various `Number` constants. */
  const INFINITY = 1 / 0;
  const result = `${value}`;
  return result === '0' && 1 / value === -INFINITY ? '-0' : result;
};
/**
 * Generate a unique ID
 *
 * @returns {string} Returns the unique ID
 */
export const uuid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : r & 0x3 || 0x8;
    return v.toString(16);
  });
};

const hasUnicodeWord = RegExp.prototype.test.bind(
  /[a-z][A-Z]|[A-Z]{2}[a-z]|[0-9][a-zA-Z]|[a-zA-Z][0-9]|[^a-zA-Z0-9 ]/,
);

/** Used to match words composed of alphanumeric characters. */
// eslint-disable-next-line
const reAsciiWord = /[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g;
// const reAsciiWord = new RegExp('[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+','g');

function asciiWords(string: string) {
  return string.match(reAsciiWord);
}

/**
 * Splits `string` into an array of its words.
 *
 * @param {string} [string=''] The string to inspect.
 * @param {RegExp|string} [pattern] The pattern to match words.
 * @returns {Array<string>} Returns the words of `string`.
 */
export const words = (string: string, pattern?: RegExp | string): Array<string> => {
  if (pattern === undefined) {
    const result = hasUnicodeWord(string) ? unicodeWords(string) : asciiWords(string);
    return result || [];
  }
  return string.match(pattern) || [];
};

export const startDate = (date: string) => `${date} 00:00:00`;
export const endDate = (date: string) => `${date} 23:59:59`;

export const formatCSVFile = 'CSV';
