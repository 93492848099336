import Api from './http-service';
import urls from '../config/constants/urls';
import {
  PaginationResquest,
  RPagination,
  GenericList,
  EmptyPagination,
  Pagination,
} from '../types/services';
import { toCamelCaseObject, toSnakeCaseObject } from '../helpers/utils';
import { buildPathWithParams } from '../helpers/url';

export interface RReseller {
  reseller_id: number | string;
  name: string;
  email: string;
  country: string;
  type: string;
  currency: string;
  balance: number;
  status: string;
  legal_status: string;
  reseller_mobile?: string;
}

export interface Reseller {
  resellerId: number | string;
  name?: string;
  email?: string;
  country?: string;
  type?: string;
  currency?: string;
  balance?: number;
  status?: string;
  legalStatus?: string;
  fee?: number;
  feeDeposit?: number;
  resellerMobile?: string;
}

export interface RResellerInfo {
  reseller_id?: number | string;
  name: string;
  email?: string;
  country?: string;
  type?: string;
  currency?: string;
  balance?: number;
  fee?: number;
  fee_deposit?: number;
  status?: string;
  legal_status?: string;
  resellers_currency_config_list?: RCurrencyConfig[];
  resellers_spread_list?: RResellerSpread[];
  mfa_enabled?: number;
  has_souce?: boolean;
  reseller_mobile?: string;
  ips?: string[];
}

export interface ResellerInfo {
  resellerId: number | string;
  name: string;
  email: string;
  country: string;
  type: string;
  currency: string;
  balance: number;
  fee: number;
  feeDeposit: number;
  status: string;
  legalStatus: string;
  resellersCurrencyConfigList: CurrencyConfig[];
  resellersSpreadList: ResellerSpread[];
  mfaEnabled: number;
  hasSource: boolean;
  resellerFatherEmail: string;
  resellerMobile?: string;
  ips?: string[];
}

export interface RCurrencyConfig {
  currency: string;
  card_sell_enabled: number;
  wallet_sell_enabled: number;
}

export interface CurrencyConfig {
  currency: string;
  cardSellEnabled: number;
  walletSellEnabled: number;
}

export interface RResellerSpread {
  currency_base: string;
  currency_target: string;
  xr_in: number;
  xr_out: number;
}

export interface ResellerSpread {
  currencyBase: string;
  currencyTarget: string;
  xrIn: number;
  xrOut: number;
}

export interface RResellerNew {
  name: string;
  email: string;
  country: string;
  type: string;
  currency: string;
  region_id: string;
  accounting_currency: string;
  reseller_mobile: string;
}

export interface Source {
  reseller_id: number;
  description: string;
}

export type RResellers = GenericList<RReseller, RPagination>;
export type Resellers = GenericList<Reseller, Pagination>;
export type RResellerSpreadList = GenericList<RResellerSpread, RPagination>;
export type ResellerSpreadList = GenericList<ResellerSpread, Pagination>;

export const EmptyResellerInfo: ResellerInfo = {
  resellerId: '',
  name: '',
  email: '',
  country: '',
  type: '',
  currency: '',
  balance: 0,
  fee: 0,
  feeDeposit: 0,
  status: '',
  legalStatus: '',
  resellersCurrencyConfigList: [],
  resellersSpreadList: [],
  mfaEnabled: 0,
  hasSource: false,
  resellerFatherEmail: '',
};

export default class ResellerService {
  static readonly getResellers = ({
    searchValue,
    country,
    type,
  }: {
    searchValue: string;
    country?: string;
    type?: string;
  }) => async (page?: PaginationResquest) => {
    try {
      const response = await Api.get<RResellers>(buildPathWithParams(urls.getResellers, {}), {
        ...page,
        search: searchValue,
        country,
        type,
      });
      return toCamelCaseObject<Resellers>(response);
    } catch {
      return { data: [], page: EmptyPagination };
    }
  };

  static readonly getSubResellers = (resellerId: string) => async (page?: PaginationResquest) => {
    try {
      const response = await Api.get<RResellers>(
        buildPathWithParams(urls.getSubResellers.replace('{requestedResellerId}', resellerId), {}),
        page,
      );
      return toCamelCaseObject<Resellers>(response);
    } catch {
      return { data: [], page: EmptyPagination };
    }
  };

  static readonly getResellerDetails = async (resellerId: string) => {
    try {
      const response = await Api.get<RResellerInfo>(
        buildPathWithParams(
          urls.getResellersDetails.replace('{requestedResellerId}', resellerId),
          {},
        ),
      );
      return toCamelCaseObject<ResellerInfo>(response);
    } catch (error: any) {
      return Promise.reject();
    }
  };

  static readonly editReseller = async (reseller: Reseller) => {
    try {
      return await Api.post(urls.editReseller, toSnakeCaseObject<RResellerInfo>(reseller));
    } catch (error: any) {
      throw error;
    }
  };

  static readonly createReseller = async (reseller: RResellerNew) => {
    try {
      return await Api.post<RResellerNew>(urls.createReseller, reseller);
    } catch (error: any) {
      throw error;
    }
  };

  static readonly mfaDisableReseller = async (resellerId: string) => {
    try {
      return await Api.post(urls.disableMfaReseller.replace('{reseller_id}', resellerId));
    } catch (error: any) {
      throw error;
    }
  };

  static readonly deactivateReseller = async (resellerId: string) => {
    try {
      return await Api.post(urls.deactivateReseller.replace('{reseller_id}', resellerId));
    } catch (error: any) {
      throw error;
    }
  };

  static readonly activateReseller = async (resellerId: string) => {
    try {
      return await Api.post(urls.activateReseller.replace('{reseller_id}', resellerId));
    } catch (error: any) {
      throw error;
    }
  };

  static readonly addSource = async (source: Source) => {
    try {
      return await Api.post(urls.addSource, source);
    } catch (error: any) {
      throw error;
    }
  };

  static readonly getResellersSpreadList = (resellerId: string) => async (
    page?: PaginationResquest,
  ) => {
    try {
      const response = await Api.get<RResellerSpreadList>(
        urls.getResellerSpreads.replace('{reseller_id}', resellerId),
        page,
      );
      return toCamelCaseObject<ResellerSpreadList>(response);
    } catch {
      return { data: [], page: EmptyPagination };
    }
  };

  static readonly addSpreadReseller = async (resellerId: string, spread: RResellerSpread) => {
    try {
      return await Api.post<RResellerSpread>(
        urls.addSpread.replace('{reseller_id}', resellerId),
        spread,
      );
    } catch (error: any) {
      throw error;
    }
  };

  static readonly editSpreadReseller = async (resellerId: string, spread: RResellerSpread) => {
    try {
      return await Api.post<RResellerSpread>(
        urls.editSpread.replace('{reseller_id}', resellerId),
        spread,
      );
    } catch (error: any) {
      throw error;
    }
  };

  static readonly removeSpreadReseller = async (
    resellerId: string,
    currencyBase: string,
    currencyTarget: string,
  ) => {
    try {
      return await Api.post(urls.removeSpread.replace('{reseller_id}', resellerId), {
        currency_base: currencyBase,
        currency_target: currencyTarget,
      });
    } catch (error: any) {
      throw error;
    }
  };

  static readonly addIp = async (resellerId: number, ip: string) => {
    try {
      const payload = {
        reseller_requested_id: resellerId,
        ip,
      };
      return await Api.post<RResellerSpread>(urls.addIp, payload);
    } catch (error: any) {
      throw error;
    }
  };

  static readonly deleteIp = async (resellerId: number | string, ip: string) => {
    try {
      const payload = {
        reseller_requested_id: resellerId,
        ip,
      };
      return await Api.post<RResellerSpread>(urls.removeIp, payload);
    } catch (error: any) {
      throw error;
    }
  };
}
