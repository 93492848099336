const Languages = [
  { code: 'pt', name: 'Português' },
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Español' },
  // { code: 'tr', name: 'Türk' },
  // { code: 'ja', name: '日本人' },
  // { code: 'ko', name: '한국의' },
  { code: 'zh', name: '簡中', local: 'zh_CN' },
  { code: 'zh_TW', name: '繁中', local: 'zh_TW' },
  { code: 'el', name: 'Ελληνικά' },
  // { code: 'th', name: 'ไทย' },
  // { code: 'id', name: 'Indonesia' },
  // { code: 'vi', name: 'Tiếng Việt' },
  // { code: 'ru', name: 'Pусский' },
];

export default Languages;
