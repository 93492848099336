import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { CircularProgress, CssBaseline } from '@mui/material';
import './styles/index.css';
import { SentryTracker } from './services/trackers/sentry';
import { FirebaseTracker } from './services/trackers/firebase';
import { SmartLookTracker } from './services/trackers/smartlook';
import { IconService } from './services';
import { trackEvents } from './config/events';
import store, { persistor } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import theme from './styles/material-theme';
import { I18nextProvider } from 'react-i18next';
import 'material-symbols';
import i18n from './config/i18n';

FirebaseTracker.init(trackEvents.firebase);
SentryTracker.init(trackEvents.sentry);
SmartLookTracker.init(trackEvents.smartLook);
IconService.init();

const queryClient = new QueryClient();

const App = React.lazy(() =>
  Promise.all([import('./app-root'), new Promise((resolve) => setTimeout(resolve, 1000))]).then(
    ([appRootModule]) => appRootModule,
  ),
);

const root = document.getElementById('root');

const LoadingDiv = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress style={{ color: theme.customColors.reds['redb200'] }} />
  </div>
);

if (root) {
  ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={LoadingDiv()}>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <PersistGate
                loading={<CircularProgress style={{ color: theme.customColors.reds['redb200'] }} />}
                persistor={persistor}
              >
                <CssBaseline />
                <App />
              </PersistGate>
            </Provider>
          </QueryClientProvider>
        </Suspense>
      </I18nextProvider>
    </React.StrictMode>,
    root,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
