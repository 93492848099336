/* eslint-disable @typescript-eslint/no-var-requires */
import { library } from '@fortawesome/fontawesome-svg-core';

const IconService = {
  init: () =>
    library.add(
      /** SOLID ICONS (PREFIX: fas (default)) */
      require('@fortawesome/pro-solid-svg-icons/faEye').definition,
      require('@fortawesome/pro-solid-svg-icons/faEyeSlash').definition,
      require('@fortawesome/pro-solid-svg-icons/faEnvelope').definition,
      require('@fortawesome/pro-solid-svg-icons/faHashtag').definition,
      require('@fortawesome/pro-solid-svg-icons/faPaperPlane').definition,
      require('@fortawesome/pro-solid-svg-icons/faEllipsisH').definition,
      require('@fortawesome/pro-solid-svg-icons/faLanguage').definition,
      require('@fortawesome/pro-solid-svg-icons/faCheckCircle').definition,
      require('@fortawesome/pro-solid-svg-icons/faExclamation').definition,
      require('@fortawesome/pro-solid-svg-icons/faChevronDown').definition,
      require('@fortawesome/pro-solid-svg-icons/faArrowLeft').definition,
      require('@fortawesome/pro-solid-svg-icons/faBars').definition,
      require('@fortawesome/pro-solid-svg-icons/faEllipsisV').definition,
      require('@fortawesome/pro-solid-svg-icons/faBell').definition,
      require('@fortawesome/pro-solid-svg-icons/faUser').definition,
      require('@fortawesome/pro-solid-svg-icons/faGlobe').definition,
      require('@fortawesome/pro-solid-svg-icons/faPhone').definition,

      /** REGULAR ICONS (PREFIX: far) */
      require('@fortawesome/pro-regular-svg-icons/faEnvelope').definition,
      require('@fortawesome/pro-regular-svg-icons/faHome').definition,
      require('@fortawesome/pro-regular-svg-icons/faSignOut').definition,
      require('@fortawesome/pro-regular-svg-icons/faTimes').definition,
      require('@fortawesome/pro-regular-svg-icons/faEmptySet').definition,
      require('@fortawesome/pro-regular-svg-icons/faInfoCircle').definition,
      require('@fortawesome/pro-regular-svg-icons/faCalendar').definition,
      require('@fortawesome/pro-regular-svg-icons/faUserCircle').definition,
      require('@fortawesome/pro-regular-svg-icons/faAngleDown').definition,
      require('@fortawesome/pro-regular-svg-icons/faAngleUp').definition,
      require('@fortawesome/pro-regular-svg-icons/faAngleLeft').definition,
      require('@fortawesome/pro-regular-svg-icons/faCommentAltDots').definition,
      require('@fortawesome/pro-regular-svg-icons/faCheck').definition,
      require('@fortawesome/pro-regular-svg-icons/faCheckDouble').definition,
      require('@fortawesome/pro-regular-svg-icons/faUser').definition,
      require('@fortawesome/pro-regular-svg-icons/faAngleRight').definition,
      require('@fortawesome/pro-regular-svg-icons/faCreditCard').definition,
      require('@fortawesome/pro-regular-svg-icons/faMoneyCheckAlt').definition,
      require('@fortawesome/pro-regular-svg-icons/faAddressBook').definition,
      require('@fortawesome/pro-regular-svg-icons/faSearch').definition,
      require('@fortawesome/pro-regular-svg-icons/faMoneyBillWave').definition,
      require('@fortawesome/pro-regular-svg-icons/faWallet').definition,
      require('@fortawesome/pro-regular-svg-icons/faPlus').definition,
      require('@fortawesome/pro-regular-svg-icons/faEllipsisH').definition,
      require('@fortawesome/pro-regular-svg-icons/faChevronDown').definition,
      require('@fortawesome/pro-regular-svg-icons/faChevronRight').definition,
      require('@fortawesome/pro-regular-svg-icons/faCheckCircle').definition,
      require('@fortawesome/pro-regular-svg-icons/faBook').definition,
      require('@fortawesome/pro-regular-svg-icons/faShareSquare').definition,
      require('@fortawesome/pro-regular-svg-icons/faExclamationTriangle').definition,
      require('@fortawesome/pro-regular-svg-icons/faListAlt').definition,
      require('@fortawesome/pro-regular-svg-icons/faDollarSign').definition,
      require('@fortawesome/pro-regular-svg-icons/faUserFriends').definition,
      require('@fortawesome/pro-regular-svg-icons/faMoneyCheckEditAlt').definition,
      require('@fortawesome/pro-regular-svg-icons/faReply').definition,
      require('@fortawesome/pro-regular-svg-icons/faCog').definition,
      require('@fortawesome/pro-regular-svg-icons/faStar').definition,
      require('@fortawesome/pro-regular-svg-icons/faGift').definition,
      require('@fortawesome/pro-regular-svg-icons/faBell').definition,
      require('@fortawesome/pro-regular-svg-icons/faShield').definition,
      require('@fortawesome/pro-regular-svg-icons/faSlidersH').definition,
      require('@fortawesome/pro-regular-svg-icons/faCoins').definition,
      require('@fortawesome/pro-regular-svg-icons/faUserEdit').definition,
      require('@fortawesome/pro-regular-svg-icons/faBadgeDollar').definition,
      require('@fortawesome/pro-regular-svg-icons/faSignOut').definition,
      /** PRO LIGHT ICONS (PREFIX: fal) */

      /** PRO DUOTONE ICONS (PREFIX: fad) */
      require('@fortawesome/pro-duotone-svg-icons/faUser').definition,
      require('@fortawesome/pro-duotone-svg-icons/faCheck').definition,
      require('@fortawesome/pro-duotone-svg-icons/faTimes').definition,
      require('@fortawesome/pro-duotone-svg-icons/faCommentsAlt').definition,
      require('@fortawesome/pro-duotone-svg-icons/faFileInvoiceDollar').definition,
      require('@fortawesome/pro-duotone-svg-icons/faDownload').definition,
      require('@fortawesome/pro-duotone-svg-icons/faPlus').definition,
      require('@fortawesome/pro-duotone-svg-icons/faEye').definition,
      require('@fortawesome/pro-duotone-svg-icons/faTrash').definition,
      require('@fortawesome/pro-duotone-svg-icons/faEdit').definition,
      require('@fortawesome/pro-duotone-svg-icons/faShareSquare').definition,
      require('@fortawesome/pro-duotone-svg-icons/faUserFriends').definition,
      require('@fortawesome/pro-duotone-svg-icons/faLock').definition,

      /** FREE BRAND ICONS (PREFIX: fab) */
      require('@fortawesome/free-brands-svg-icons/faWhatsapp').definition,
      require('@fortawesome/free-brands-svg-icons/faTelegramPlane').definition,
    ),
};

export default IconService;
