import { EventProps, UserEvent } from '../types/services';
import { trackEvents } from '../config/events';
import { SmartLookTracker } from './trackers/smartlook';
import { SentryTracker } from './trackers/sentry';
import { FirebaseTracker } from './trackers/firebase';

const ignoreErrorCall = (action: () => void) => {
  try {
    action();
  } catch {}
};

export const trackUser = (user: UserEvent, newUser = false) => {
  ignoreErrorCall(() => {
    if (trackEvents.firebase) {
      FirebaseTracker.trackUser(user, newUser);
    }
    if (trackEvents.smartLook) {
      SmartLookTracker.trackUser(user);
    }
    if (trackEvents.sentry) {
      SentryTracker.trackUser(user);
    }
  });
};

export const trackEvent = (eventName: string, props: EventProps) => {
  ignoreErrorCall(() => {
    if (trackEvents.firebase) {
      FirebaseTracker.trackEvent(eventName, props);
    }
    if (trackEvents.smartLook) {
      SmartLookTracker.trackEvent(eventName, props);
    }
  });
};

export const trackError = (error: Error, errorInfo: React.ErrorInfo) => {
  ignoreErrorCall(() => {
    if (trackEvents.sentry) {
      SentryTracker.trackError?.(error, errorInfo);
    }
  });
};

export const trackPageView = (page: string) => {
  ignoreErrorCall(() => {
    FirebaseTracker.trackPageView(page);
  });
};
