import Api from './http-service';
import urls from '../config/constants/urls';
import {
  PaginationResquest,
  RPagination,
  GenericList,
  EmptyPagination,
  Pagination,
} from '../types/services';
import { toCamelCaseObject, toSnakeCaseObject } from '../helpers/utils';
import { buildPathWithParams } from '../helpers/url';

export interface RPayment {
  payment_id: number;
  reseller_id: number;
  payment_method_id: number;
  country: string;
  payment_currency: string;
  payment_amount: number;
  commission: number;
  creation_date: string;
  expiration_date: string;
  status: string; // "APPROVED" | "PENDING"
  receipt_url?: string;
}

export interface Payment {
  paymentId: number;
  resellerId: number;
  paymentMethodCode: string;
  country: string;
  paymentCurrency: string;
  paymentAmount: number;
  commission: number;
  creationDate: string;
  expirationDate: string;
  status: string; // "APPROVED" | "PENDING"
  receiptUrl?: string;
}

export interface ResellerResponse {
  reseller_id: number;
  email: string;
  balance_list: {
    currency: string;
    balance: number;
  }[];
  rating: number;
  mfa_enabled: boolean;
  unread_notifications: number;
  equivalence_balances_currency: {
    currency: string;
    balance: number;
  }[];
  exchange_value: number;
}

export interface ResellerInfo {
  resellerId: number;
  email: string;
  balanceList: {
    currency: string;
    balance: number;
  }[];
  rating: number;
  mfaEnabled: boolean;
  unreadNotifications: number;
  equivalenceBalancesCurrency: {
    currency: string;
    balance: number;
  }[];
  exchangeValue: number;
}
export const EmptyResellerInfoDashboard: ResellerInfo = {
  resellerId: 0,
  email: '',
  balanceList: [],
  rating: 0,
  mfaEnabled: false,
  unreadNotifications: 0,
  equivalenceBalancesCurrency: [],
  exchangeValue: 0,
};

export interface RPhoneInfo {
  reseller_id: number;
  phone_number: string;
}

export interface PhoneInfo {
  resellerId: number;
  phoneNumber: string;
}

export type RPayments = GenericList<RPayment, RPagination>;
export type Payments = GenericList<Payment, Pagination>;

export default class DashBoardService {
  static readonly getPayments = (resellerId: string) => async (page?: PaginationResquest) => {
    try {
      const response = await Api.get<RPayments>(
        buildPathWithParams(urls.getPayments, { resellerId }),
        page,
      );
      return toCamelCaseObject<Payments>(response);
    } catch {
      return { data: [], page: EmptyPagination };
    }
  };

  static readonly getData = async () => {
    try {
      const response = await Api.get<ResellerResponse>(urls.dashboard);
      return toCamelCaseObject<ResellerInfo>(response);
    } catch (error: any) {
      return Promise.reject();
    }
  };

  static readonly addPhone = async (payload: PhoneInfo) => {
    try {
      return await Api.post(urls.addPhone, toSnakeCaseObject<RPhoneInfo>(payload));
    } catch (error: any) {
      throw error;
    }
  };
}
