/* eslint-disable @typescript-eslint/naming-convention */
enum DateFormat {
  RegularDate = 'L',
  DisplayBirthDate = 'DD/MM/YYYY',
  BirthDate = 'YYYY-MM-DD[T00:00:00Z]',
  RegularDateTime = 'L H:mm:ss',
  TextDateTime = 'll LTS',
  TextDate = 'll',
  MonthAndYear = 'MM/YYYY',
}

export default DateFormat;
