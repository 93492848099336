import Api from './http-service';
import urls from '../config/constants/urls';
import { toCamelCaseObject, toSnakeCaseObject } from '../helpers/utils';

export interface RMessage {
  message_id: number;
  from: 'RESELLER' | 'USER';
  message: string;
  payment_id: number;
  external_reference: string;
  creation_date: string;
  is_message_read: 0 | 1;
}

export interface Message {
  messageId: number;
  from: 'RESELLER' | 'USER';
  message: string;
  paymentId: number;
  creationDate: string;
  isMessageRead: 0 | 1;
  externalReference?: string;
}

export interface MessageRequest {
  message: string;
  paymentId: number;
  externalReference?: string;
}

export interface MessageRequestBE {
  message: string;
  payment_id: number;
  external_reference?: string;
}

export type RMessages = { messages: RMessage[] };
export type Messages = { messages: Message[] };

export type MessageRead = 'ALL' | 'READ' | 'UNREAD';

export default class ChatService {
  static getMessages = async (paymentId: string, type: MessageRead) => {
    try {
      const request: { payment_id: string; unread?: boolean } = {
        payment_id: paymentId,
      };
      switch (type) {
        case 'READ':
          request.unread = false;
          break;
        case 'UNREAD':
          request.unread = true;
          break;
        case 'ALL':
        default:
      }
      const response = await Api.get<RMessages>(urls.getMessages, request);
      return toCamelCaseObject<Messages>(response);
    } catch {
      return { messages: [] };
    }
  };

  static sendMessages = async (messages: MessageRequest[]) => {
    try {
      const response = await Api.post<RMessages>(urls.sendMessages, {
        messages: messages.map((m) => toSnakeCaseObject<MessageRequestBE>(m)),
      });
      return toCamelCaseObject<Messages>(response);
    } catch (e: any) {
      return Promise.reject(e.message);
    }
  };

  static markMessagesAsRead = async (messageIds: number[]) => {
    return await Api.post(urls.markMessagesAsRead, { message_ids: messageIds });
  };
}
