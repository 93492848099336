import { createSelector } from 'reselect';

import { GlobalState, RequestError } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { EmptyResellerInfo, Reseller, ResellerInfo } from '../../services/resellers-service';
import { Country } from '../../services/countries-service';
import {
  EmptyResellerInfoDashboard,
  ResellerInfo as ResellerInfoDashboard,
} from '../../services/dashboard-service';

const defaultCountry = 'BR';

const initialState: GlobalState = {
  resellerDashboard: EmptyResellerInfoDashboard,
  error: undefined,
  countryByIp: defaultCountry,
  phoneNumberContact: '',
  isoCountryContact: '',
  toCardIssuanceGroup: false,
  countriesList: [],
  resellerEmail: '',
  currentResellerToEditId: '',
  currentResellerToEdit: EmptyResellerInfo,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setResellerUser: (state, action: PayloadAction<ResellerInfoDashboard>) => {
      state.resellerDashboard = action.payload;
    },
    setCountryByIP: (state, action: PayloadAction<string>) => {
      state.countryByIp = action.payload;
    },
    setPhoneNumberContact: (state, action: PayloadAction<string>) => {
      state.phoneNumberContact = action.payload;
    },
    setIsoCountryContact: (state, action: PayloadAction<string>) => {
      state.isoCountryContact = action.payload;
    },
    setResellerEmail: (state, action: PayloadAction<string>) => {
      state.resellerEmail = action.payload;
    },
    setCountriesList: (state, action: PayloadAction<Country[]>) => {
      state.countriesList = action.payload;
    },
    setCurrentResellerToEditId: (state, action: PayloadAction<string>) => {
      state.currentResellerToEditId = action.payload;
    },
    setCurrentResellerToEdit: (state, action: PayloadAction<ResellerInfo | Reseller>) => {
      state.currentResellerToEdit = action.payload;
    },
    setToCardIssuanceGroup: (state, action: PayloadAction<boolean>) => {
      state.toCardIssuanceGroup = action.payload;
    },
    requestFailed: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    clearResellerDashboard: (state) => {
      state.resellerDashboard = EmptyResellerInfoDashboard;
    },
    clearResellerToEdit: (state) => {
      state.currentResellerToEdit = EmptyResellerInfo;
      state.currentResellerToEditId = '';
    },
  },
});

const getGlobal = (state: RootState): GlobalState => state.global;
export const getResellerDashboard = createSelector(getGlobal, (global) => global.resellerDashboard);
export const getError = createSelector(getGlobal, (global) => global.error);
export const getCountryByIp = createSelector(getGlobal, (global) => global.countryByIp);
export const getPhoneNumberContact = createSelector(
  getGlobal,
  (global) => global.phoneNumberContact,
);
export const getIsoCountryContact = createSelector(getGlobal, (global) => global.isoCountryContact);
export const getCountriesList = createSelector(getGlobal, (global) => global.countriesList);
export const getResellerEmail = createSelector(getGlobal, (global) => global.resellerEmail);
export const getToCardIssuanceGroup = createSelector(
  getGlobal,
  (global) => global.toCardIssuanceGroup,
);
export const getCurrentResellerToEdit = createSelector(
  getGlobal,
  (global) => global.currentResellerToEdit,
);
export const getCurrentResellerToEditId = createSelector(
  getGlobal,
  (global) => global.currentResellerToEditId,
);
const { actions, reducer } = globalSlice;

export const {
  setResellerUser,
  setIsoCountryContact,
  setPhoneNumberContact,
  setCountryByIP,
  setToCardIssuanceGroup,
  setCountriesList,
  setResellerEmail,
  setCurrentResellerToEditId,
  setCurrentResellerToEdit,
  requestFailed,
  clearError,
  clearResellerDashboard,
  clearResellerToEdit,
} = actions;

export default reducer;
