import Firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/remote-config';
import 'firebase/performance';
import { EventProps, TrackerEvent, UserEvent } from '../../types/services';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

type OurUserProps =
  | 'apc_user_country'
  | 'apc_user_id'
  | 'apc_user_email_status'
  | 'apc_user_last_login_date'
  | 'apc_user_has_cards'
  | 'apc_user_lang'
  | 'apc_user_cashout_enabled'
  | 'apc_user_invite_users'
  | 'apc_user_kyc_status'
  | 'apc_user_last_buy_date'
  | 'apc_user_last_buy_status'
  | 'apc_user_loqate'
  | 'apc_user_purchasers'
  | 'apc_user_refunds'
  | 'apc_user_registered'
  | 'apc_user_sign_up_date'
  | 'apc_user_source'
  | 'apc_user_status'
  | 'apc_user_whitelist'
  | 'firebase_last_notification';

type UserPropsObject = {
  [key in OurUserProps]: string | number | boolean;
};

type RemoteConfigParams =
  | 'isHelpCenterEnabled'
  | 'purchaseDetailTimerRelease'
  | 'timeoutSMS'
  | 'purchaseDetailTimerDelay'
  | 'purchaseDetailTimerTemplate'
  | 'purchaseDetailTimerWithHours'
  | 'isInviteFriendEnabled'
  | 'isPurchasePromoCodeEnabled'
  | 'checkUserNotificationTime';

export class FirebaseTracker extends TrackerEvent {
  /**
   * Initialize Firebase intance
   * @param init boolean
   */
  static init = (init: boolean) => {
    if (init) {
      try {
        Firebase.initializeApp(firebaseConfig);

        // REMOTE CONFIG
        const remoteConfig = Firebase.remoteConfig();
        remoteConfig.settings = {
          fetchTimeoutMillis: 60000,
          minimumFetchIntervalMillis: 3000,
        };
        remoteConfig.defaultConfig = {
          isHelpCenterEnabled: true,
          purchaseDetailTimerRelease: 2400,
          purchaseDetailTimerDelay: 30,
          purchaseDetailTimerWithHours: false,
          purchaseDetailTimerTemplate: '{ "templates": ["BD_BR"] }',
          timeoutSMS: 60,
          isInviteFriendEnabled: false,
          isPurchasePromoCodeEnabled: false,
          checkUserNotificationTime: 300,
        };
        // LOAD REMOTE CONFIG
        remoteConfig.fetchAndActivate().then().catch();
        // LOAD PERFORMANCE
        Firebase.performance();
        // LOAD ANALYTICS
        Firebase.analytics();
      } catch {}
    }
  };

  /**
   * Track event on firebase
   * @param eventName string
   * @param props { [key: string]: string | number | booolean }
   */
  static trackEvent = (eventName: string, props: EventProps) => {
    Firebase.analytics().logEvent(eventName, props);
  };

  static trackPageView = (page: string) => {
    Firebase.analytics().setCurrentScreen(page);
  };

  /**
   * Track user on Firebase
   * @param user UserEvent
   * @param newUser boolean
   */
  static trackUser(user: UserEvent, newUser = false) {
    Firebase.analytics().setUserId(user.id.toString());
    if (newUser) {
      this.trackNewUser(user);
    } else {
      this.setUserProps({ apc_user_last_login_date: new Date().toISOString().slice(0, 10) });
    }
  }

  private static trackNewUser(user: UserEvent) {
    const currentTime = new Date().toISOString().slice(0, 10);
    this.setUserProps({
      apc_user_id: user.id,
      apc_user_country: user.country,
      apc_user_registered: true,
      apc_user_lang: user.language,
      apc_user_has_cards: false,
      apc_user_invite_users: false,
      apc_user_purchasers: false,
      apc_user_refunds: false,
      apc_user_last_login_date: currentTime,
      apc_user_sign_up_date: currentTime,
    });
  }

  private static setUserProps = (userProps: Partial<UserPropsObject>) => {
    try {
      Firebase.analytics().setUserProperties(userProps);
    } catch {}
  };

  static getRemoteConfig = (
    parameter: RemoteConfigParams,
    type: 'boolean' | 'number' | 'string' | 'object',
  ) => {
    const remoteConfig = Firebase.remoteConfig();
    const value = remoteConfig.getValue(parameter);
    switch (type) {
      case 'boolean':
        return value.asBoolean();
      case 'number':
        return value.asNumber();
      case 'object':
        return JSON.parse(value.asString());
      default:
        return value.asString();
    }
  };

  static getAllRemoteConfigs = () => {
    const remoteConfig = Firebase.remoteConfig();
    remoteConfig.fetchAndActivate();
  };
}
