import Api from './http-service';
import urls from '../config/constants/urls';
import { toCamelCaseObject } from '../helpers/utils';
import { User } from '../redux/session/types';

export interface LoginRequest {
  username: string;
  password: string;
}

interface AuthResponse {
  reseller_id: number;
  validity: number;
  user_name: string;
  name: string;
  country: string;
  mfa_enabled: boolean;
  image?: string;
}

export interface ChangePasswordRequest {
  email: string;
  code: string;
  password: string;
}

export interface ActivateMFAResponse {
  mfa_url?: string;
  secret?: string;
}

export default class AuthService {
  static login = async (request: LoginRequest) => {
    try {
      const response = await Api.post<AuthResponse>(urls.login, {
        user_name: request.username,
        password: request.password,
      });
      return toCamelCaseObject<User>(response);
    } catch (error: any) {
      throw error;
    }
  };

  static authenticateMFA = async (code: string, resellerId: number, hashJwt: string) => {
    try {
      const response = await Api.post<AuthResponse>(urls.authMfa, {
        mfa_code: code,
        reseller_id: resellerId,
        hash_jwt: hashJwt,
      });
      return toCamelCaseObject<User>(response);
    } catch (error: any) {
      throw error;
    }
  };

  static activateMFA = async () => {
    try {
      const response = await Api.post<ActivateMFAResponse>(urls.createMfa);
      return response.mfa_url ?? response.secret ?? '';
    } catch (error: any) {
      throw error;
    }
  };

  static validateMFA = async (code: string) => {
    try {
      const response = await Api.post<string>(urls.validateMfa, { mfa_code: code });
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  static forgotPassword = async (email: string) => {
    return await Api.post<string>(urls.forgotPass, { email });
  };

  static recoveryPassword = async (request: ChangePasswordRequest) => {
    return await Api.post<string>(urls.recoveryPassword, {
      email: request.email,
      new_password: request.password,
      auth_code: request.code,
    });
  };

  static logout = async () => {
    return await Api.post<string>(urls.logout);
  };
}
