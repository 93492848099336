import SmartlookClient from 'smartlook-client';
import { EventProps, TrackerEvent, UserEvent } from '../../types/services';

export class SmartLookTracker extends TrackerEvent {
  static init = (init: boolean) => {
    if (init) {
      SmartlookClient.init(process.env.REACT_SMART_LOOK_KEY || '');
    }
  };

  static trackUser = (user: UserEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SmartlookClient.identify(user.id, user as any);
  };

  static trackEvent = (eventName: string, props: EventProps) => {
    SmartlookClient.track(eventName, props);
  };
}

// export const init = () => {
//   smartlookClient.init(process.env.REACT_SMART_LOOK_KEY || '');
// };

// export const trackUser = (user: UserEvent) => {
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   smartlookClient.identify(user.id, user as any);
// };

// export const trackEvent = (eventName: string, props: EventProps) => {
//   smartlookClient.track(eventName, props);
// };
