import { ObjectOf } from './global';

export interface UserEvent {
  id: string;
  country: string;
  language: string;
  mobile: string;
  email?: string;
  username?: string;
}

export type EventProps = ObjectOf<string | number | boolean>;

export abstract class TrackerEvent {
  /**
   * Initialize service tracker
   * @param init boolean
   */
  static init: (canInit: boolean) => void;
  /**
   * Track event
   * @param eventName string
   * @param props { [key: string]: string | number | booolean }
   */
  static trackEvent: (eventName: string, props: EventProps) => void;
  /**
   * Track error
   * @param error Error
   * @param errorInfo React.ErrorInfo
   */
  static trackError?: (error: Error, errorInfo: React.ErrorInfo) => void;
  /**
   * Track user
   * @param user UserEvent
   * @param newUser boolean
   */
  static trackUser: (user: UserEvent, newUser?: boolean) => void;
}

export interface GenericList<T, P> {
  data: T[];
  page: P;
}

export interface PaginationResquest {
  page: number;
  size?: number;
  sort?: string;
}

export interface RPagination {
  page_number: number;
  page_size: number;
  total: number;
  sort: string;
}

export interface Pagination {
  pageNumber: number;
  pageSize: number;
  total: number;
  sort: string;
}

export const EmptyPagination = {
  pageNumber: 1,
  pageSize: 20,
  total: 0,
  sort: '',
};
