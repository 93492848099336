import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { EventProps, TrackerEvent, UserEvent } from '../../types/services';
import { history } from '../../config/constants/routes';

export class SentryTracker extends TrackerEvent {
  /**
   * Initialize Sentry intance
   * @param init boolean
   */
  static init = (init: boolean) => {
    if (init) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
          }),
        ],
        environment: process.env.REACT_APP_ENV,
        release: `${process.env.REACT_APP_SENTRY_PROJECT}@${process.env.REACT_APP_VERSION}`,
      });
    }
  };

  /**
   * Track event on Sentry
   * @param eventName string
   * @param props { [key: string]: string | number | booolean }
   */
  static trackEvent(eventName: string, props: EventProps) {
    this.trackSentryEvent(eventName, props);
  }

  /**
   * Internal track event
   * @param message string
   * @param tags { [key: string]: string | number | boolean}
   * @param level Sentry.Severity
   */
  static trackSentryEvent = (
    message: string,
    tags?: EventProps,
    level: Sentry.Severity = Sentry.Severity.Info,
  ) => {
    Sentry.configureScope(() => {
      Sentry.captureEvent({
        message,
        level: level,
        tags: tags,
      });
    });
  };

  /**
   * Track user on Sentry
   * @param user UserEvent
   * @param newUser boolean
   */
  static trackUser = (user: UserEvent) => {
    Sentry.configureScope((scope) => {
      scope.setUser(user ? user : null);
      scope.setTag('country', user.country || 'unknown');
      scope.setTag('version', process.env.REACT_APP_VERSION || '');
    });
  };

  /**
   * Track error on Sentry
   * @param error Error
   * @param errorInfo React.ErrorInfo
   */
  static trackError(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.configureScope((scope) => {
      scope.setExtras({ ...errorInfo });
      Sentry.captureException(error);
    });
  }
}
